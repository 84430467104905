.search-autocomplete{
   .wrapper{
    height: auto;
    margin: 0;
    z-index: 1 !important;
    padding: 5px 15px;
    font-size: inherit !important;
    font-family: inherit !important;
    line-height: inherit;
   }
   .hUWNiw{
    margin: 0 15px;
   }
   .ctrVxg{
    margin: 3px 14px 0px 0px;
   }
}

input::-webkit-input-placeholder , textarea::-webkit-placeholder { /* Edge */
    color: grey;
  }
  
  input::-ms-input-placeholder , textarea::-ms-placeholder { /* Internet Explorer 10-11 */
    color: grey;
  }
  
  input::placeholder , textarea::placeholder { 
    color: grey;
  }

  // select:invalid,
  // select option[value=""] {
  //   color: #999999;
  // }


  select{
    color: grey !important;
    option{
      color: #000;
    }
  }

.report_res{
  grid-template-columns : 3fr 3fr;
  overflow-wrap: anywhere;
  text-align: unset;
  .DashboardMainPage-res--actions{
    justify-content: space-between;
  }
  .button{
    font-size: 14px;
    min-width: 70px !important;
    width: unset !important;
    padding: 10px !important;
    @include respond(phone) {
      font-size: 12px !important;
     }
  }
  @include respond(big-phone) {
    grid-template-columns : 2fr 4fr;
    // .DashboardMainPage-res--actions{
    //   justify-content: flex-end;
    //   margin-top: 10px;
    //   grid-column: 1/-1;
    // }
  }

  @include respond(phone) {
   font-size: 12px !important;
  }
 
}

.orphan_report_res{
  grid-template-columns : 2fr 2fr 2fr 2fr;
  overflow-wrap: anywhere;
  text-align: unset;
 @include respond(phone) {
  grid-template-columns : 2fr 2fr 1fr;
  .button--green{
    font-size: 12px;
  }
  .font-res-header{
    font-size: 10px !important;
  }
   }
  //  .report-icon-box{

  //  }
}
.report-detail-grid{
  @include respond(big-phone) {
    font-size: 12px;
  }
  @include respond(phone) {
    font-size: 10px;
  }
}


  .App__en{
    input{
      &::placeholder{
        text-align: left;
      }
      &::-webkit-input-placeholder{
        text-align: left;
      }
      &::-ms-input-placeholder{
        text-align: left;
      }
    }

    textarea{
      text-align: left !important;
      &::placeholder{
        text-align: left !important;
      }
    }

    select{
      text-align: left !important;
    }
  }

