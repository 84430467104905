/**
* * Rest style
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  direction: rtl;
  height: auto;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

.App__en {
  direction: ltr;
  text-align: left !important;
}
.App__fa {
  direction: rtl;
  text-align: right;
   li.ant-pagination-next {
    transform: rotate(180deg);
  }
  li.ant-pagination-prev {
    transform: rotate(180deg);
  }
}
a {
  color: $color-text !important;
}
p {
  color: $color-text;
}
.Pagination {
  margin: 40px auto;
  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
    display: none;
  }
  .ant-pagination-item:nth-child(-2n + 6) {
    background-color: #ebf3f7;
  }
  ul.ant-pagination {
    width: max-content;
    margin: 0 auto;
    display: flex;
    // flex-direction: row-reverse;
    button {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      border-radius: 5px;
      border: none;
    }
    .ant-pagination-item-active {
      border: none;
      background: #1375a3;
      /* color: #fff; */
      a {
        color: #fff !important;
      }
    }
  }
}

.container {
  @include respond(sm-laptop) {
    max-width: 90% !important;
  }
  @include respond(big-tablet) {
    max-width: 100% !important;
  }
}


