.HomeNum {
    display: flex;
    justify-content: space-around;
    margin: 80px 0;
    &__box {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &--num {
            margin: 10px 0;
            font-size: 6rem;
            @include respond(surface-duo){
                font-size: 4rem;
            }
        }
        &--p {
            margin: 10px 0;
            font-size: 2.4rem;
            color: #6f838e;
        }
    }
    &__circle {
        width: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(tab-port) {
            width: 120px;
            height: 120px;
            background-position: center;
            background-size: cover;
        }
        @include respond(phone) {
            width: 100px;
            height: 100px;
        }
    }
    &__circle--1 {
        background-image: url("../../../assets/img/Home/back-purple.png");
    }
    &__circle--2 {
        background-image: url("../../../assets/img/Home/back-pink.png");
    }
    &__circle--3 {
        background-image: url("../../../assets/img/Home/back-blue.png");
    }
}
.HomeNum__con {
    @include respond(tab-port) {
        display: none;
    }
}
.HomeNumResponsive {
    display: none;
    margin: 20px 0;
   
    @include respond(tab-port) {
        display: block;
    }
    
    .slick-prev {
        left: 7%;
        top: 71%;
        width: 143px;
        z-index: 1;
        @include respond(surface-duo) {
            width: 40px;
            left: 0;
        }
    }
    .slick-next {
        right: 7%;
        top: 71%;
        width: 143px;
        z-index: 1;
        @include respond(surface-duo) {
            width: 40px;
            right: 0;
        }
    }
    .slick-slide {
        
        transform: scale(.7);
        .HomeNum__box--num,
        .HomeNum__box--title {
            display: none;
        }
        transition: .2s;
    }
    .slick-slide.slick-active.slick-center {
        transform: scale(1);
        .HomeNum__box--num,
        .HomeNum__box--title {
            display: block;
        }
        transition: .2s;
    }
    .slick-track {
        .slick-slide:first-child {
          opacity: 1 !important;
        }
      }
}
