.make__phase {
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  @include respond(phone) {
    justify-content: flex-start;
  }
  &--box {
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-direction: column-reverse;
    // width: 70px;

    @include respond(small-tablet) {
      justify-content: flex-end;
    }
    p {
      // white-space: nowrap;
      color: #313e45;
      font-size: 13px;
      text-align: center;
      @include respond(small-tablet) {
        white-space: normal;
        text-align: center;
      }
      @include respond(phone) {
        margin-bottom: 4px;
        white-space: normal;
      }
    }

    .phase_text {
      @include respond(big-phone) {
        font-size: 12px;
      }
      @include respond(surface-duo) {
        font-size: 10px;
      }
    }
  }
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #d8e7ef;
    margin: 1rem 0;
    color: #ffffff;
    font-size: 14px;
    position: relative;
    z-index: 1;
    color: #313e45;
    @include respond(big-phone) {
      width: 40px;
      height: 40px;
    }
  }

  &-line {
    width: 8.4rem;
    height: 2px;
    margin-top: -32px;
    position: relative;
    z-index: 0;

    background-color: #d8e7ef;
    // border-bottom: 1px solid rgba(18, 18, 18, 0.3);
    &:last-child {
      display: none;
    }
    @include respond(big-phone) {
      margin-top: -15px;
    }
    @include respond(smallest-phone) {
      width: 6rem;
    }
	@include respond(galaxy-fold){
		width: 4rem;
	}
  }
}
