.App__fa{
  .button{
    &__primary{
      direction: rtl;
    }
  }
}
.App__en{
  .button{
    .arrow{
      rotate: 180deg;
    }
  }
}
.button{
  padding: 10px 20px;
  border-radius: $br-input;
  color: $color-white;
  border: none;
  font-size: 1.6rem;
  white-space: nowrap;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
  .arrow{
    margin-top: 5px;
  }
 &__primary{
  background-image: linear-gradient(
    to right,
    $color-primary 0%,
    $color-primary-dark 100%,
  );
 }
 &__secondary{
  background:#fff;
  color:$color-primary ;
  padding: 10px 35px;
 }
 
 
}

.button__outlined{
  background-color: transparent;
  color: $color-gray-light;
  border: 1px solid $color-gray-light;
  padding: 7px 15px;

}
.SecButton{
  padding: 10px 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background:#92a0b0;
  border: none;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}
//loading btn
.wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 50px auto;
}

.leftHalf,
.rightHalf,
.spinner {
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
}

.leftHalf {
  left: 0;
  background: transparent;
  z-index: 3;
  opacity: 1;
  animation: showHide 10s infinite steps(1, end);
  border-radius: 100% 0 0 100%/ 50% 0 0 50%;
}

.rightHalf {
  right: 0;
  background: #ff8330;
  z-index: 1;
  opacity: 0;
  animation: showHide 10s infinite steps(1, end) reverse;
  border-radius: 0 100% 100% 0/ 0 50% 50% 0;
}

.spinner {
  overflow: hidden;
  left: 0;
  background: #ff8330;
  animation: spin 2s linear infinite;
  transform-origin: center right;
  z-index: 2;
  border-radius: 100% 0 0 100%/ 50% 0 0 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes showHide {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

.App__en{
  .button-arrow{
    transform: rotate(180deg);
  }
}