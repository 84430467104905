.AboutUs{
    font-size: 1.6rem;
    color: #313E45;
    
    &__title{
        font-size: 3rem;
        margin-top: 20px;
        @include respond(tab-port) {
            font-size: 2.3rem;
      
          }
    }
    p{
        margin-bottom: 0;
        text-align: justify;
        line-height: 1.8;
    }
    .AboutUscontainer{
        h5{
            font-size: 4rem;
            margin: 20px 0;
        }
        &__firstrow{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 50px;
            @include respond(tab-port) {
              display: flex;          
              flex-direction: column-reverse;

              }
        }
    }
    .aboutus__footer{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 50px;
        margin: 40px 0;
        @include respond(tab-port) {
            display: flex;          
            flex-direction: column-reverse;
            
            }

    }
    .aboutus--naghl{
        font-size: 2rem;
        margin: 40px 0;
        @include respond(tab-port) {
            display: flex;          
            flex-direction: column;

            }
    }
}

.breadcrumbs_arrow{
    margin-top: 4px;
}

.App__en{
    .breadcrumbs_arrow{
        transform: rotate(180deg);
    }
}
