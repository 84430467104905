
.News{
    &__title{

    }
    // .HomeNewsCard__infos{
    //     align-items: flex-start;
    // }
    .NewsContainer{
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 20px;
        @include respond(tab-port) {
            display: flex;
            flex-direction: column-reverse;
    
    
            }
        &__grid{
            // display: grid;
            // grid-template-columns: repeat(3,1fr);
            // grid-gap: 20px;
            // @include respond(tab-port) {
            //     grid-template-columns: repeat(1,1fr);       
            //     }

            .HomeNewsCard__infobox{
                width: 95%;
                @include respond(sm-laptop) {
                    width: 100%;
                    font-size: 12px;      
                    }

                    img{
                        @include respond(sm-laptop) {
                            width: 14px      
                            } 
                    }
            }
            .HomeNewsCard{
                @include respond(sm-laptop) {
                    margin: 0 5px;     
                    }
            } 
        }
    }
   
}
.aboutus--naghl{
    @include respond(tab-port) {
      flex-direction: column;


        }
}
.blue-box{
    border-radius:25px ;
    background-color: #EBF3F7;
    margin-bottom: 20px;
}
.News__title{
    font-size: 2.2rem;
    margin-bottom: 10px;
}
.NewsCat{
    font-size: 1.4rem;
    &__ul{
        li{
            margin: 10px 0;
            color: #313E45;

        }
    }
}
.Number-box{
    border-radius: 5px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    text-align: center;
    color: #313E45;
}
.LastNewsBox{
    display: flex;
    align-items: center;
    height: 85px;
    margin: 20px 0;
    &__date{
        color: #6F838E;
    }
    p{
        margin-bottom: 2px;
    }
    img{
        width: 85px;
        height: 85px;
        border-radius: 5px;
    }
    
}
.NewsPeivast{
    display: flex;
    flex-wrap: wrap;
    .Tag{
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
    }
}
.News__container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    select{
        width: 328px;
        margin-bottom: 20px;
        @include respond(sm-laptop) {
            width: 300px;
            margin: 0 auto;
          
          }
    }
}
.NewsDetailinfo{
    font-size: 1.8rem;
    .HomeKefalat__info {
        width: 100%;
    }
    .HomeKefalat__info--p{
        font-size: 1.8rem;
    }
}
.LastNews__grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    @include respond(sm-laptop) {
        grid-template-columns: repeat(1,1fr);
      
      }
    @include respond(tab-port) {
        grid-template-columns: repeat(1,1fr);


        }
    .HomeNewsCard__infobox{
        width: 90%;
    }
}
.News__h3{
    font-size: 3rem;
    margin: 4rem 0;
}
.NewsContainer{
    margin: 40px 0;
    &__column{
        max-width: 320px;
        @include respond(tab-port){
            max-width: 100%;
        }
    }

    
}
ul.slick-dots {
    background: #fff;
    box-shadow: 0 0 5px #eaeaea;
    width: max-content;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: -50px;
    border-radius: 8px;
    padding: 5px 10px;
    transform: translateX(-50%);
   
}
.slick-dots li.slick-active button:before {
    color: #1375A3 !important;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px !important;
    line-height: 20px;
    position: absolute;
    top: -4px !important;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #D8E7EF !important;
   
}

.news_search_icon{
    top: 3px;
    font-size: 22px;
    color: #aeaeae;
}

.App__fa{
    .row_reverse{
        flex-direction: row-reverse;
    }
    .news_search_icon{
        left: 10px;
        right: unset;
    }
}

