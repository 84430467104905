.ContactusContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 80px 0;
    @include respond(tab-port) {
        display: flex;
        flex-direction: column;
        padding: 80px 40px;

    }
    h5 {
        font-size: 3rem;
    }
    .ContactusContainer__info{
        @include respond(tab-port) {
            margin-bottom: 40px;

        }
    }
    .Footer-address {
        width: 50%;
        @include respond(tab-port) {
            width: 90%;
           

        }
    }
    .contact-us-form-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        .Input {
            width: 154px;
            @include respond(tab-port) {
               width: 100%;
               
    
            }
        }
        @include respond(tab-port) {
            grid-template-columns: 1fr;
           

        }
    }
    form {
        box-shadow: 0px 0px 5px #a6b8cc;
        padding: 40px;
        border-radius: 25px;
        margin: 10px;
        width: 400px;
        @include respond(tab-port) {
            box-shadow: 0px 5px 10px 0px #a6b8cc69;
        }

        @include respond(smallest-phone) {
            width: 300px;
        }
        //   
        button {
            width: 100%;
            margin-top: 10px;
        }
    }
    .danger{
        color: #ff4d4f;
    }
}
