.Homeslider {
  margin-top: -37px;
  position: relative;
  z-index: 1;
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #fff;
    justify-content: center;
    position: relative;
    z-index: 999;
    p {
      font-size: 2rem;
      margin: 30px 0;
      padding: 0 30px;
      width: 60%;
      text-align: center;
      color: #fff;
      overflow-wrap: anywhere;
      @include respond(smallest-phone){
        font-size: 16px;
      }
    }
    h2 {
      font-size: 6rem;
      color: #fff;
      text-align: center;
      overflow-wrap: anywhere;
      @include respond(tab-port) {
        font-size: 30px;

         }
        
    }
  }
  &__slides {
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 75vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
  
  }
  &__slides {
    // background-image: url("../../../assets/img/Home/Slider\ \(6\).png");
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        to top,
        rgba(#11171b, 0.8),
        rgba(#11171b, 0.4)
      );
    }
  }

  .slick-next {
    right: 100px;
    width: 41px;
    z-index: 999;
    @include respond(surface-duo) {
     display: none !important;
      }
  }
  .slick-prev {
    left: 100px;
    width: 41px;
    z-index: 999;
    @include respond(surface-duo) {
      display: none !important;
       }
  }
  .slick-dots{
    bottom: -20px;
  }
  // @include respond(sm-laptop) {
  //   margin-top: 0;
  // }
}
