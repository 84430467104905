.HomeKefalat {
    @include respond(tab-port) {
        flex-direction: column;
        margin-bottom: 40px;
    }

    h4 {
        font-size: 4.3rem;
        color: #313E45;

        @include respond(tab-port) {
            font-size: 2.3rem;
            margin-top: 20px;


        }
    }

    &__info {
        width: 60%;

        @include respond(tab-port) {
            width: 88%;
        }
    }
 &__img{
     img{
        @include respond(sm-laptop) {
           width: 98%;
          
          }
     }
 }
    &__info--p {
        display: flex;
        font-size: 1.7rem;
        color: #353D40;
        align-items: center;

        span {
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            // margin-top: 10px;

            img {
                width: 36px;
            }
        }
    }
}

.Home__shareBack {
    position: relative;
    overflow-x: hidden;

    &::before {
        content: "";
        background-image: url("../../../assets/img/Home/Group\ 67604.svg");
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-203px, -10px);
        width: 100%;
        height: 100%;
        z-index: -1;
        background-repeat: no-repeat;
    }

}