.RegisterModal {
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    backdrop-filter: blur(6px);
  &__container {
    background-color: #fff;
    width: 100vw;
    height: 60vh;
   
  }
}
.Modal__logo {
  height: 263px;
  width: 263px;
  background: #fff;
  transform: translate(0, 15%);
  
  img {
    width: 126px;
  }
}
.Modal__paper{
  width: 565px;
  background-color:#fff;
  overflow-y: auto;
  padding: 30px;
  border-radius: 20px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  
  
  &::-webkit-scrollbar-track {
    background: #eeeeee; 
  }
   
  /* Handle */
  
  
  &::-webkit-scrollbar-thumb {
    background: rgb(202, 201, 201); 
  }
  
  /* Handle on hover */
  
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

.modal_strap_header{
  border-bottom: none !important;
  .btn-close{
    margin: 0 5px !important;
  }
}

.modal_strap_body{
  padding-top: 0 !important;
}