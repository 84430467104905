.registerBox{
    width: 610px;
    border-radius: 25px;
    box-shadow: 0px 0px 9px #eaeaea;
    padding:35px;
    @include respond(phone) {
        width: 100%;
        box-shadow: none;

    }
    .PhoneRegister{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .flag-dropdown {
       left: 0;
    }
    .react-tel-input .form-control{
width: 100%;
    }
}
.react-code-input {
    direction: ltr;
    input{
        &:focus{
         //   border: 1px solid #074979 !important;
        }
        @include respond(phone) {
            width: 40px !important;
            height: 40px !important;
    
        }
    }
}
.CodeRagister__back{
    width: 380px;
    margin: 0 auto;
    @include respond(phone) {
        width: 100%;
        box-shadow: none;

    }
  }
  .CodeRagister{
    display: grid;
    grid-template-columns: repeat(5,57px);
  margin: 20px 0;
  justify-content: center;
  grid-gap: 10px;
  direction: ltr;
    .Input{
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
      }
      input{
        width: 57px;
        height: 55px;
        background-color: #f4f8fa;
        border: none;
        margin: 0;
      text-align: center;
      
        &:focus{
          border: none;
  outline: none;
        }
        &::placeholder {
          color: #6f838e;
          text-align: center;
        }
      }
    }
  }
  .PhoneRegister__box {
    direction: rtl;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0;
    &-select {
     
        background-color: #f4f8fa;
        padding: 8px 10px;
        border: none;
        border-radius: 8px;
        margin-right: 10px;
      direction: ltr;
      color: #6F838E;
        &:focus{
          border: none;
  outline: none;
        }
        &::placeholder {
          color: #6f838e;
          text-align: center;
        }
      
    }
    &-input {
      input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
      input {
        background-color: #f4f8fa;
        padding: 8px 10px;
        border: none;
        margin: 0;
        -webkit-appearance: none;
        width: 250px;
        @include respond(phone) {
            width: 190px;
           
        }
        &:focus{
          border: none;
  outline: none;
        }
        &::placeholder {
          color: #6f838e;
          text-align: center;
        }
      }
    }
    .phone_number_input{
      text-align: left;
      direction: ltr;
    }
  }
  .PhoneRegister{
    h4{
      color: #313E45;
      font-size: 3rem;
    }
    .button__primary {
      width: 100%;
  }
  }