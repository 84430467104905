.container-lg {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 5rem;
  padding-left: 5rem;
  height: inherit;
  max-width: 1920px;

  @include respond(big-desktop) {
    // max-width: 1800px;
  }

  @include respond(phone) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.container {
  margin: 0 auto;
  overflow: hidden;
  height: 100%;

  max-width: 1200px;

  @include respond(big-desktop) {
    max-width: 1300px;
  }

  @include respond(sm-laptop) {
    max-width: 1000px;
  }

  @include respond(tab-port) {
    max-width: 800px;
  }

  @include respond(tablet) {
    max-width: 700px;
  }

  @include respond(phone) {
    max-width: 450px;
  }

  @include respond(smallest-phone) {
    max-width: 350px;
  }
}

.d-flex {
  @include display-flex;
}

.d-flex-col {
  @include d-flex-column;
}

.d-flex-align {
  display: flex;
  align-items: center;
}

.d-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.d-flex-align-end {
  display: flex;
  align-items: flex-end;
}

.b-radius {
  &-3 {
    border-radius: 3rem;
  }

  &-2 {
    border-radius: 2rem;
  }

  &-1 {
    border-radius: 1rem;
  }
}

.circle {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.grid-temp-col-sm {
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.relative {
  position: relative;
}

.float_back {
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
}

.temp__pic {
  width: 85px;
  height: 85px;
  background-color: darkblue;
}

.trans__back {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: end;
  backdrop-filter: blur(6px);
}

.background-fix {
  background-color: rgba(47, 47, 47, 0.5);
  backdrop-filter: blur(6px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Social {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: fit-content;

  @include respond(phone) {
    grid-gap: 8px;
  }
}

.icon-box {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  width: 45px;
  height: 45px;
  background-color: #d8e7ef;
  border: 1px solid rgb(245, 249, 251);

  @include respond(phone) {
    min-width: 30px;
    width: 30px;
    height: 30px;

    img {
      width: 15px;
    }
  }
}

.scroll-bar {
  overflow-x: auto;
  @include respond(big-phone) {
    width: 500px;
    overflow-x: auto;
    margin: 0 auto;
  }

  @include respond(phone) {
    width: 306px;
    overflow-x: auto;
    margin: 0 auto;
  }

  .Tag {
    white-space: nowrap;
  }
}

.primary-box-border {
  width: 100%;
  background-color: #fff;
  margin: 5px 0;
  padding: 17px 15px;
  color: $color-primary-dark-1;
  border-radius: 10px;
  border: 1px solid #a6b8cc;

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 125px 46px 77px 67px 100px;
    grid-gap: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.orphan-table-grid{
  display: grid;
  grid-template-columns: 280px 100px 100px 100px 300px;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include respond(laptop) {
    display: grid;
    grid-template-columns: 280px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(ipad-air) {
    display: grid;
    grid-template-columns: 200px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 169px 18px 84px 50px 174px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
    font-size: 12px;
  }

  @include respond(phone) {
    display: grid;
    grid-template-columns: 56px 35px 74px 65px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
    font-size: 10px;
  }

  @include respond(smallest-phone) {
    display: grid;
    grid-template-columns: 56px 35px 74px 65px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
  }
}

.tarakonesh-table-grid {
  display: grid;
  grid-template-columns: 280px 100px 100px 100px 300px;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include respond(laptop) {
    display: grid;
    grid-template-columns: 280px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(ipad-air) {
    display: grid;
    grid-template-columns: 200px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 169px 18px 84px 50px 174px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
    font-size: 12px;
  }

  @include respond(phone) {
    display: grid;
    grid-template-columns: 56px 35px 74px 65px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
    font-size: 10px;

    .tarakonesh-table-grid-action {
      grid-column: 1/5;
    }
  }

  @include respond(smallest-phone) {
    display: grid;
    grid-template-columns: 56px 35px 74px 65px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;

    .tarakonesh-table-grid-action {
      grid-column: 1/5;
    }
  }
}

.payment-table-grid {
  display: grid;
  grid-template-columns: 336px 70px 100px 118px 97px;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-radius: 15px;

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 116px 35px 74px 65px 79px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
    font-size: 12px;
  }

  @include respond(smallest-phone) {
    display: grid;
    grid-template-columns: 56px 35px 74px 65px 79px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
  }
}

.kafilrow-res {
  @include respond(laptop) {
    display: grid;
    grid-template-columns: 280px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(ipad-air) {
    display: grid;
    grid-template-columns: 200px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 169px 18px 84px 50px 174px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
    font-size: 12px;
  }

  @include respond(phone) {
    display: grid;
    grid-template-columns: 156px 35px 84px 65px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
    grid-row-gap: 20px;
    font-size: 12px;

    .tarakonesh-table-grid-action {
      grid-column: 1/5;
    }
  }

  @include respond(smallest-phone) {
    grid-template-columns: 111px 37px 86px 47px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }

  @include respond(smallest-phone1) {
    grid-template-columns: 139px 30px 70px 40px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }

  @include respond(galaxy-fold) {
    grid-template-columns: 94px 23px 81px 47px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 10px 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }
}

.App__en {
  .table-grid-4 {
    text-align: left;
  }
}

.table-grid-4 {
  display: grid;
  grid-template-columns: 200px 100px 100px 433px;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  text-align: right;

  @include respond(laptop) {
    display: grid;
    grid-template-columns: 280px 100px 100px 1fr;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(ipad-air) {
    display: grid;
    grid-template-columns: 100px 100px 100px 1fr;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 90px 90px 70px 1fr;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(phone) {
    display: grid;
    grid-template-columns: 125px 87px 135px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    grid-gap: 0px;
    grid-row-gap: 20px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
      .button {
        max-width: 100px !important;
        padding: 5px 10px;
      }
    }
  }

  @include respond(smallest-phone) {
    grid-template-columns: 125px 87px 135px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }

  @include respond(smallest-phone1) {
    grid-template-columns: 82px 81px 124px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
      .button {
        width: 80px !important;
        padding: 5px 10px;
        font-size: 10px !important;
      }
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }

  @include respond(galaxy-fold) {
    grid-template-columns: 72px 71px 103px;
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 10px 4px;

    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
    }

    .font-res-header {
      font-size: 12px !important;
    }
  }
}

.Accordion--header {
  height: 55px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Accordion {
  transition: all 0.5s ease-in;
  margin-top: 10px;
  overflow: hidden;

  svg {
    transition: all 0.3s ease-in;
  }
}

.Accordion--open {
  max-height: 1000px;
  transition: all 0.3s ease-in;

  .square-icon {
    svg {
      transform: rotate(180deg);
      transition: all 0.5s ease-in;
    }
  }
}

.Accordion--close {
  max-height: 70px;
}

.square-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.res-mr-0 {
  @include respond(big-phone) {
    margin-right: 0 !important;
  }
}
.res-ml-0 {
  @include respond(big-phone) {
    margin-left: 0 !important;
  }
}
.res-mr-20 {
  @include respond(big-phone) {
    margin: 20px auto;
  }
}

.TarakoneshTable-res {
  width: 100%;
  @include respond(laptop) {
    display: grid;
    grid-template-columns: 280px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(ipad-air) {
    display: grid;
    grid-template-columns: 200px 100px 100px 100px 200px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(tablet) {
    display: grid;
    grid-template-columns: 169px 85px 84px 80px 130px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
  }

  @include respond(big-phone) {
    grid-template-columns: 200px 84px 80px 120px;
    .payment_type {
      display: none;
    }
  }

  @include respond(surface-duo) {
    grid-template-columns: 150px 84px 80px 100px;
    .transaction-details{
      display: none;
    }
    // .payment_amount {
    //   display: none;
    // }
    // &.table_header {
    //   display: none;
    // }
  }

  @include respond(phone) {
    grid-template-columns: 125px 70px 70px 60px;
    .square-icon{
      width: 25px;
      height: 25px;
    }
  }

  @include respond(smallest-phone) {
    grid-template-columns: 30px 70px 70px 60px;
    .payment_id{
      display: none;
    }
  }

  @include respond(galaxy-fold) {
    // grid-template-columns: 130px 80px;
  }
}
.Accordion {
  @include respond(phone) {
    //  width: max-content;
  }
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  @include respond(tablet) {
    max-width: 760px;
    overflow: auto;
  }

  @include respond(phone) {
    max-width: 260px;
    overflow: auto;
  }

  @include respond(smallest-phone) {
    max-width: 260px;
    overflow: auto;
  }

  @include respond(smallest-phone1) {
    max-width: 260px;
    overflow: auto;
  }

  @include respond(galaxy-fold) {
    max-width: 260px;
    overflow: auto;
  }
}
.res-displaynone {
  @include respond(phone) {
    display: none;
  }
}
.edit-profile-res {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.res-center {
  @include respond(phone) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.res-justify-start {
  justify-content: start !important;
}
.res-display-none {
  @include respond(big-phone) {
    display: none;
  }
}
.res-display-block {
  display: none;
  @include respond(big-phone) {
    display: block;
  }
}

.primary-select-flex-column {
  @include respond(big-phone) {
    flex-direction: column !important;
    .primary-select {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.gap-10px {
  grid-gap: 10px;
}

