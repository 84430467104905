.Gallery{
    &__header{
        border-bottom: 1px solid #EEF2F4;
        padding-bottom: 30px;
        .Tag{
            cursor: pointer;
        }
    } 
     select{
         width:320px;
         @include respond(tab-port) {
          display: none;
          }
     }
 
     &__container{
         display: grid;
         grid-template-columns: repeat(3,1fr);
         grid-gap: 6rem;
         margin: 20px 0;
         @include respond(tab-port) {
            grid-template-columns: repeat(1,1fr);
            margin:  0 40px;

          }

          @include respond(smallest-phone) {
            margin: 0;
          }
     }
     &__video{
        .GalleryCard{
            &__img{
                max-height: 250px;
            }
        }
     }
 }
 .GalleryCard{
     position: relative;
     margin-bottom: 19px;
     &__img{
         position: relative;
          border-radius: 15px;
          overflow: hidden;
          height: 100%;
        &::before{
         background-image: linear-gradient(rgba(0, 0, 0, 0.1) ,rgba(0, 0, 0, 0.623));
         border-radius: 15px;
         overflow: hidden;
         z-index: 1;
         position: absolute;
         content: "";
         width: 100%;
         height: 100%;
         top: 0;
        }
 
 
     }
     &__info{
       display: flex;
       justify-content: flex-start;
       flex-direction: column;
       position: absolute;  
       bottom: -37px;
       left: 0;
       width: 100%;
       padding: 0 10px;
       z-index: 1;
      
       h2{
           font-size: 1.8rem;
           color: #fff;
       }
       .HomeNewsCard__infobox{
        width: 95%;
        @include respond(sm-laptop) {
            width: 100%;
            font-size: 12px;      
            }

            img{
                @include respond(sm-laptop) {
                    width: 14px      
                    } 
            }
       }
     }
 }

 ///GalleryVideo


 .GalleryVideo{
     font-size: 1.8rem;
     margin: 40px 0;
     .container{
         width: 1000px;
     }
     
     &__title{
         font-size: 4.3rem;
         color: #313E45;
         margin: 20px 0;
         text-align: center;
         @include respond(tab-port) {
            font-size: 2.3rem;


          }
     }
     .video-detail-wrapper{
        height: 300px;
        overflow: hidden;
     }
     .videoCardDetai{
        .GalleryCard{
            max-height: 250px;
        }
     }
     .video-thubnail{
         border-radius: 15px;
         width: 100%;
        @include respond(tab-port) {
          width: 350px;

          }
     }
     img{
         width: 100%;
     }
 h3{
     font-size: 2.8rem;
     margin: 20px 0;

 }
 }
 #ReactSimpleImageViewer{
     z-index: 999999999;
     background-color: rgba(#181D21, 0.87);
     
     .styles-module_navigation__1pqAE{
 display: none;
    }
 }
 