.faq {
    font-size: 1.6rem;
    color: #313E45;

	&__accordion {
		& .ant-collapse-header {
     font-size: 2rem;
     color: #313E45;
     font-weight: bold;
			& > span {
				margin-right: 4rem;
			}
		}
		& .ant-collapse-item-active {
			& .ant-collapse-header {
				color: #1375A3;
			}
		}
.ant-collapse-item{
    border-right: 1px solid #1375A3 ;
    margin: 15px 0;
}
		& .ant-collapse-content-box {
			padding: 12px 3rem;

			& p {
				line-height: 1.8;
			}
		}

		&--icon {
			width: 2.2rem;
			height: 2.2rem;
			display: flex !important;
			align-items: center;
			justify-content: center;

			&::after,
			&::before {
				content: '';
				border-radius: 4px;
				background-color: #1375A3;
				position: absolute;
			}

			&::after {
				width: 100%;
				height: 2px;
			}
			&::before {
				width: 2px;
				height: 100%;
				transition: all 300ms;
			}

			&.active {
				&::before {
					transform: rotate(90deg);
				}
			}
		}
	}
}
