.Footer {
  background-image: url("../../assets/img/Home/footerbg.png");
  height: 100%;
  font-size: 1.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  @include respond(tab-port) {
    background-image: url("../../assets/img/Home/footer-mob.svg");
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    // padding-bottom: 100px;
  }
  p {
    margin-bottom: 0;
  }
  &__con {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 200px;
    @include respond(tab-port) {
      grid-template-columns: 1fr;
      margin-top: 0;
      // padding-bottom: 20px;
    }
  }
  h6 {
    font-size: 26px;
    color: #313e45;
    @include respond(sm-laptop) {
      font-size: 20px;
    }
  }
  &__list {
    li {
      display: flex;
      align-items: center;
      margin: 20px 0;
      color: #313e45;
      font-size: 1.6rem;
      .arrow {
        margin-bottom: -5px;
      }
    }
  }
  &__input {
    input {
      &::placeholder {
        text-align: right;
      }
    }
  }
  .footer__logo {
    @include respond(tab-port) {
      margin-top: 15px;
    }
  }

  input[name="email"] {
    text-align: left;
    &::placeholder {
      text-align: center;
    }
  }
}
.FooterMenu-ul {
  width: 50vw;
  margin: 20px auto;
  @include respond(big-tablet) {
    width: 60vw;
  }
  @include respond(tab-port) {
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  li {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
    .arrow {
      margin-bottom: -5px;
    }
  }
}
.SubFooter {
  background-color: #11171b;
  color: #a6b8cc;
  padding: 15px 0;

  @include respond(sm-laptop){
    padding-bottom: 80px;
  }
  @include respond(big-phone){
    padding-bottom: 120px;
    .copy_right{
      justify-content: center;
      .conditions {
        display: none;
      }
    }

    font-size: 12px;
  }

}
.namad-img {
  margin-top: 10px;
  width: 80px;
  height: 80px;
}
.ersal-btn {
  position: absolute;
  right: 16px;
  top: 15px;
}
.App__en {
  .arrow {
    transform: rotate(180deg);
  }
  // .Footer__con{
  //   grid-template-columns:  repeat(3, 1fr);
  //   @include respond(tab-port) {
  //     grid-template-columns: 1fr;
  //     margin-top: 0;
  //     padding-bottom: 20px;

  //   }
  // }
}
