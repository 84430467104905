.Input {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  label {
    color: rgba($color: $color-gray-dark, $alpha: 0.7);
    font-size: 11px;
  }
  input {
    border: none;
    padding: 10px 15px;
    background-color: #fff;
    margin: 5px 0;

    color: $color-primary-dark-1;
    border-radius: 10px;
    border: 1px solid #a6b8cc;
    &:focus {
      outline: none;
      border: 1px solid #a6b8cc;
    }
    &::placeholder {
      // text-align: center;
    }
  }
  textarea {
    border: none;
    padding: 10px 15px;
    background-color: #fff;
    margin: 5px 0;

    color: $color-primary-dark-1;
    border-radius: 10px;
    border: 1px solid #a6b8cc;
    &:focus {
      outline: none;
      border: 1px solid #a6b8cc;
    }
    &::placeholder {
      text-align: center;
    }
  }
  .react-tel-input .flag-dropdown {
    border: none;
    background: #f1f1f1;
    padding: 21px 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .react-tel-input .form-control {
    border: none;
    background: #f1f1f1;
    padding: 21px 0;
    padding-left: 48px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 190px;
    //   padding: 23px 0;
    //   font-size: 1.6rem;
  }
  .react-tel-input {
    border-radius: 8px;
  }
}

.input__message{
  font-size: 12px !important;
}
.checkbox {
  display: flex;
  align-items: center;
  label {
    color: rgba($color: $color-gray-dark, $alpha: 0.7);
    font-size: 11px;
    a:link {
      color: $color-primary;
      text-decoration: underline;
    }
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    background: $color-white-light;
    appearance: none;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
      border: none;
    }
    &::before {
      content: "\2713";

      color: #f1f1f1;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 900;
    }
    &:checked {
      background: $color-primary-dark;
    }
  }
}
.input__icon {
  position: absolute;
  right: 10px;
  top: 32px;
}
.input__eyes {
  position: absolute;
  right: 10px;
  top: 28px;
  cursor: pointer;
  &--span-active {
    height: 20px;
    animation: increaseWidth 0.2s;
    animation-iteration-count: 1;
  }
  &--span-deactive {
    animation: decreaseWidth 0.2s;
    animation-iteration-count: 1;
  }
  &--span {
    position: absolute;
    left: 50%;
    top: 2px;
    width: 2px;
    background-color: #292e3d;
    transform: rotate(45deg);
  }
}
@keyframes increaseWidth {
  from {
    height: 0px;
  }
  to {
    height: 20px;
  }
}
@keyframes decreaseWidth {
  from {
    height: 20px;
  }
  to {
    height: 0px;
  }
}
.App__en{
  .Select {
    &__Arrow {
      right: 20px !important;
    } 
    select {
      text-align: left !important;
    }
  }
}

.App__fa{
  .Select {
    &__Arrow {
      left: 20px;
      right: unset !important;
    } 
    select {
      text-align: right;
    }
  }
}

.Select {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  position: relative;

  &__Arrow {
    position: absolute;
    top: 30%;
  }
  select {
    border: none;
    padding: 10px 15px;
    background-color: #fff;
    margin: 5px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color-primary-dark-1;
    border-radius: 10px;
    border: 1px solid #a6b8cc;

    &:focus {
      outline: none;
      border: 1px solid #a6b8cc;
    }
    &::placeholder {
      text-align: center;
    }
  }
  &.filter-select {
    @include respond(big-phone) {
      font-size: 12px;
    }
  }
}
.profile__uploader--cover{
  width: 140px;
  height:140px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.UploadProfile--uploadcoverbox{
 input{
  display: none;
 }
}
.UploadProfile__label {
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: linear-gradient(
    to right,
    $color-primary 0%,
    $color-primary-dark 100%,
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  top: 0;
  left: 0;
}

.UploadFile{
  input{
    display: none;
  }
 
}
.UploadMadrak{
  width: 100% !important;
  img{
    width: 20px;
  }
}
.UploadFile__button{
  width: 85%;
  background-color: #fff;
  margin: 5px 0;
  padding: 16px 15px;
  color: $color-primary-dark-1;
  border-radius: 10px;
  border: 1px solid #a6b8cc;

}
.App__en{
  .UploadIcon{
    margin-left: 20px;
    margin-right: 0;
  }
}
.UploadIcon{
  background-color: #fff;
  margin: 5px 0;
  color: $color-primary-dark-1;
  border-radius: 10px;
  border: 1px solid #a6b8cc;
  width: 54px;
  height: 54px;
  margin-right: 20px;
}