.message_wrapper {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  .messages_box {
    height: 500px;
    padding: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8c8c8c;
      border-radius: 10px;
    }

    .message {
      min-width: 300px;
      max-width: 50%;
      margin: 5px 0;
      padding: 10px;
      padding-bottom: 2px;
      display: inline-block;
      word-wrap: break-word;
      &.user_message {
        border: 1px solid #f9f9f9;
        border-radius: 15px 0 15px 15px;
        background: #f9f9f9;
      }
      &.responder_message {
        border: 1px solid #EBF3F7;
        border-radius: 0px 15px 15px 15px;
        background: #EBF3F7;
      }
      @include respond(smallest-phone) {
        min-width: 100%;
      }
    }
  }
  .message_input textarea {
    margin: 0 !important;
    height: 100px;
    border: none;
    border-top: 1px solid #d0d0d0;
    border-radius: 0 0 10px 10px;
    &::-webkit-scrollbar {
       display: none;
      }
  }
  .send_msg_btn {
    top: 50%;
    transform: translateY(-50%);
  }
}

.App__fa {
  .send_msg_btn {
    left: 10px;
  }
  .message_input textarea {
    padding-left: 120px;
  }
}

.App__en {
  .send_msg_btn {
    right: 10px;
  }
  .message_input textarea {
    padding-right: 120px;
  }
}
