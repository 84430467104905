.DashboardLayout {
  display: grid;
  grid-template-columns: 1fr 3.9fr;
  grid-gap: 40px;
  align-items: flex-start;
  margin-top: 40px;
  .rmdp-container {
    width: 100%;
  }
  @include respond(sm-laptop) {
    grid-template-columns: 1fr;
    margin: 0 20px;
  }

  .HomeNum__circle {
    width: 160px;
    height: 100%;
  }

  .danger {
    color: #ff4d4f;
  }

  .Input textarea {
    border: none;
    padding: 10px 15px;
    background-color: #fff;
    margin: 5px 0;
    color: #292e3d;
    border-radius: 10px;
    border: 1px solid #a6b8cc;
    resize: none;
    height: 200px;
    text-align: right;

    &::placeholder {
      text-align: right;
    }
  }

  .Pagination {
    margin: 0;
  }

  .Input input {
    border: none;
    padding: 16px 15px;
    background-color: #fff;
    margin: 5px 0;
    color: #292e3d;
    border-radius: 10px;
    border: 1px solid #a6b8cc;
  }

  .rmdp-input {
    border: 1px solid #a6b8cd;
    border-radius: 11px;
    height: 57px;
    margin: 5px 0;
    padding: 2px 13px;
    width: 100%;

    &::placeholder {
      color: #292e3d;
    }
  }

  svg.icon.icon-tabler.icon-tabler-calendar-event {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: inherit;
    cursor: pointer;
  }

  .Select select {
    padding: 16px 15px;
    text-align: right;
  }

  .Select__Arrow {
    position: absolute;
    top: 38%;
    right: 88%;
  }
  .main-user {
    min-width: 0;
    .title {
      font-size: 22px;
      @include respond(big-phone) {
        font-size: 18px;
      }
    }
    .row_wrapper {
      max-height: 400px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #8c8c8c;
        border-radius: 10px;
      }
    }
    .accept-orphan {
      @include respond(phone) {
        flex-direction: column-reverse;
        button {
          &:first-child {
            margin-top: 10px;
          }
        }
      }
    }
    .transaction_section{
    .transaction_header{
      @include respond(phone) {
        align-items: flex-start !important;
      } 
      }
    }
  }
  .tabel {
    @include respond(big-phone) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .header-text {
    @include respond(big-phone) {
      font-size: 25px;
    }
    @include respond(smallest-phone) {
      font-size: 20px;
    }
  }

  .unselexted_orphan_text {
    @include respond(phone) {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .identifire_code {
    @include respond(big-phone) {
      display: none;
    }
  }
  .yearly_salary {
    @include respond(big-phone) {
      display: none;
    }
  }
}

.DashboardInfo-grid {
  grid-template-columns: 0.5fr 3fr;
  grid-gap: 20px;
}

.tooltipCopy {
  border-radius: 6px;
  width: 120px;
  position: absolute;
  /* display: block; */
  top: -27px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  margin: 0 5px;
  text-align: center;
  z-index: 1;
}

.select_searchable {
  .css-13cymwt-control {
    border-color: #a6b8cc !important;
    border-radius: 10px;
    margin: 5px 0;
    padding: 8px 15px;
    .css-qbdosj-Input {
      color: #292e3d !important;
    }
    .css-1dimb5e-singleValue {
      color: #292e3d !important;
    }
  }
  .css-t3ipsp-control {
    border-color: #a6b8cc !important;
    border-radius: 10px;
    margin: 5px 0;
    padding: 8px 15px;
    box-shadow: unset;
    .css-qbdosj-Input {
      color: #292e3d !important;
    }
  }
}

.DashboardInfo-grid-2 {
  display: grid;
  grid-gap: 20px;

  grid-template-columns: 1fr 1fr 2fr;
}

.DashboardHome__box {
  padding: 20px 40px;
  border-radius: 20px;
}

.DashboardHome__box--1 {
  background-color: rgba($color: #a360b7, $alpha: 0.3);

  //
  .button {
    background: #a360b7 !important;
    margin: 20px;
  }
}

.DashboardHome__box--2 {
  background-color: rgba($color: #f76e52, $alpha: 0.3);

  //
  .button {
    background: #f76e52 !important;
    margin: 20px;
  }
}

.DashboardHome__box--3 {
  background-color: rgba($color: #b4d6d7, $alpha: 0.3);

  .button {
    background: #63d2d5 !important;
    margin: 20px;
  }
}

.primary-tab {
  .ant-tabs-tab {
    margin: 0 0 0 32px;
    color: #6f838e;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #313e45;
    text-shadow: 0 0 0.25px currentcolor;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #313e45;
    pointer-events: none;
  }

  .ant-tabs-tab:hover {
    color: #313e45;
  }
}

.blue-back {
  background-color: #f4f8fa;
  padding: 10px;
  border-radius: 10px;
  &.payment_popup_cancel_btn {
    color: gray;
    @include respond(phone) {
      display: none;
    }
  }
}

.payment_green_btn {
  @include respond(phone) {
    width: 100%;
  }
}

.uncheck {
  width: 20px;
  height: 20px;
  border: 2px solid #2dca73;
  border-radius: 4px;
}

.blue-back-2 {
  background-color: #1375a3;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  margin: 0 10px;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

.primary-select {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;

  @include respond(phone) {
    width: 240px;
    margin: 0 auto;
  }

  @include respond(smallest-phone) {
    width: 218px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 100%;
    padding: 12px 11px;
    border-radius: 10px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #f4f8fa;
    border: none;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-select-arrow {
    right: 89%;
  }

  .ant-select-selection-placeholder {
    color: #313e45;
  }
}

.filter-select-wrapper {
  @include respond(big-phone) {
    width: 250px;
  }
  @include respond(phone) {
    margin: 0 !important;
  }
}

.App__en {
  direction: ltr;
  text-align: left !important;
  .ant-select-arrow {
    right: 11px;
  }
  .date-input {
    right: 20px;
  }
  svg.icon.icon-tabler.icon-tabler-calendar-event {
    right: 20px !important;
    left: unset !important;
  }

  .tooltipCopy {
    left: unset;
    right: 0;
  }
}

.App__fa {
  direction: rtl;
  text-align: right;
  li.ant-pagination-next {
    transform: rotate(180deg);
  }
  li.ant-pagination-prev {
    transform: rotate(180deg);
  }
  .date-input {
    left: 20px;
  }
  svg.icon.icon-tabler.icon-tabler-calendar-event {
    left: 20px !important;
    right: unset !important;
  }
}

.MessageDetailtable--grid {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include respond(tab-port) {
    // grid-template-columns: 124px 1fr 1fr 1fr;
    grid-gap: 0;
  }
}

.grid-cols-1-responsive {
  @include respond(tab-port) {
    grid-template-columns: 1fr;
  }
}

.flex-col-responsive {
  @include respond(phone) {
    flex-direction: column;
    align-items: baseline;
  }
}

.flex-col-responsive-payment {
  @include respond(big-phone) {
    flex-direction: column-reverse;
    align-items: baseline;
    margin-bottom: 15px;
  }
}

.overflow-scroll {
  @include respond(tab-port) {
    max-width: 800px;
    overflow-x: auto;
  }

  @include respond(big-phone) {
    max-width: 320px;
    overflow-x: auto;
  }

  @include respond(phone) {
    max-width: 320px;
    overflow-x: auto;
  }

  @include respond(smallest-phone) {
    max-width: 290px;
    overflow-x: auto;
  }
}

.KafilPopup--img {
  grid-row: 1/6;
  height: 100%;

  @include respond(tab-port) {
    grid-row: 1;
  }
}

.KafilTablePopup--button {
  width: 200px;
  color: #1375a3;
}

.button--green {
  background: #2dca73;
  color: #fff;
  padding: 10px 35px;

  @include respond(phone) {
    padding: 5px 20px;
  }
}

.button--red {
  background: #ff1d25;
  color: #fff;
  padding: 10px 35px;

  @include respond(phone) {
    padding: 5px 20px;
  }
}

.DashboardMainPage-res {
  grid-template-columns: 4fr 1fr 2fr 3fr 4fr;
  overflow-wrap: anywhere;
  .f-w-500 {
    font-weight: 500;
  }
  .orphan-list-img {
    min-width: 50px;
  }

  .button {
    min-width: 70px;
    padding: 5px 10px;
    font-size: 1.3rem;
  }

  @include respond(laptop) {
    grid-gap: 10px;
    justify-content: space-between;
  }

  @include respond(big-tablet) {
    grid-gap: 10px;
  }

  @include respond(tab-port) {
    // grid-template-columns: 6fr 2fr 2fr 4fr 0fr;
    grid-gap: 0;
    grid-row-gap: 10px;
    // .add_orphan_table_action {
    //   grid-column: 1/-1;
    //   justify-content: end;
    // }
    .select_btn {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @include respond(surface-duo) {
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: center;
  }

  @include respond(big-phone) {
    grid-template-columns: 4fr 1fr 3fr 4fr;
  }

  @include respond(phone) {
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: center;
    grid-template-columns: 6fr 2fr 4fr;
    grid-row-gap: 10px;
    .DashboardMainPage-res--actions {
      grid-column: 1/-1;
      justify-content: end;
    }

    .add_orphan_table_action {
      grid-column: 1/-1;
      justify-content: end;
    }
  }

  //
  @include respond(smallest-phone) {
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 10px 4px;
    .orphan-list-img {
      min-width: 30px;
    }
    .DashboardMainPage-res--actions {
      .detail-btn {
        margin: 0 10px;
        .text {
          display: none;
        }
      }
    }
  }

  @include respond(smallest-phone1) {
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
  }

  @include respond(galaxy-fold) {
    grid-gap: 0;
    grid-row-gap: 29px;
    justify-content: flex-start;
    font-size: 10px !important;
    padding: 10px 4px;

    locale--actions {
      grid-column: 1/-1;
    }
  }
  &.DashboardMainPage-res-1 {
    grid-template-columns: 3fr 1fr 2fr 2fr 4fr;

    @include respond(big-phone){
      grid-template-columns: 3fr 1fr 2fr;
      .DashboardMainPage-res--actions {
        grid-column: 1/-1;
        justify-content: end;
    }
    }

    @include respond(tab-port) {
      font-size: 12px;
      .details {
        display: none;
      }
    }
  }
}

.message-res {
  display: grid;
  grid-template-columns: 250px 2fr 1fr 1fr !important;
  overflow-wrap: anywhere;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;

  .message-title{
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    line-height: 2;
  }

  .icon-box {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  @include respond(tablet) {
    font-size: 12px;
  }

  @include respond(big-phone) {
    grid-template-columns: 200px 2fr 1fr 1fr !important;
    .icon-box {
      min-width: 35px;
      width: 35px;
      height: 35px;
    }
  }
  @include respond(surface-duo) { 
    grid-template-columns: 150px 2fr 1fr 1fr !important;
    .message-title{
      font-size: 12px;
    }
  }
  @include respond(phone) {
    grid-template-columns: 120px 1fr 1fr 1fr !important;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    grid-gap: 0px;
    font-size: 10px;

    .tarakonesh-table-grid-action {
      grid-column: 1/5;
    }
  }

  @include respond(smallest-phone) {
    grid-template-columns: 100px 1fr 1fr 1fr !important;
    .message-title{
      font-size: 10px;
    }
  }

  @include respond(smallest-phone1) {
    grid-template-columns: 80px 1fr 1fr 1fr !important;
  }
}

.res-profile {
  width: 45px;
  height: 45px;
  @include respond(big-phone) {
    width: 30px;
    height: 30px;
  }
}

.badge-res {
  font-size: 13px;
  font-weight: 500;
  top: -5px;
  @include respond(big-phone) {
    width: 15px;
    height: 15px;
    top: -3px !important;
    font-size: 10px;
  }
}

.font-res-header {
  @include respond(tablet) {
    font-size: 12px !important;
  }
  @include respond(surface-duo) {
    font-size: 10px !important;
  }
}

.details {
  @include respond(big-phone) {
    display: none;
  }
}

@include respond(surface-duo) {
  .message-button {
    span {
      display: none;
    }
    &::after {
      content: "+";
    }
  }
}

.currency {
  font-size: 18px;
  color: #b3b3b3;
  font-weight: 400;
  text-align: center;
}

.payment_cost {
  @include respond(phone) {
    flex-direction: column;
  }
}
