.Menu {
  padding: 24px 0 80px 0;
  background-color: $color-primary-light;
  @include respond(sm-laptop) {
    display: none;
  }
  &__ul {
    margin-bottom: 0;
  }

  &__li {
    margin: 0 20px;
    font-size: 14px;
    white-space: nowrap;
    @include respond(tab-port) {
      margin: 0 10px;
    }
    a {
      color: $color-text;
    }
  }
  &__info {
    font-size: 1.7rem;

    &--select {
      background-color: transparent !important;
    }
  }
}

.SubMenu {
  border-radius: 15px;
  box-shadow: 1px 1px 9px rgba(192, 190, 190, 0.933);
  padding: 4px 20px;
  margin: 10px 5px;
  position: relative;
  z-index: 99;
  background-color: #fff;

  @include respond(sm-laptop) {
    border-radius: 0;
    margin: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    box-shadow: 5px 0px 5px 4px rgba(190, 220, 243, 0.1);
  }
  @include respond(smallest-phone) {
    padding: 4px 10px;
  }

  &-row {
  }

  &-ul {
    margin-bottom: 0;

    @include respond(sm-laptop) {
      display: none;
    }
  }

  &-li {
    font-size: 14px;
    margin: 0 12px;
    @include respond(big-tablet) {
      margin: 0 10px;
    }
  }

  &__btn {
    @include respond(sm-laptop) {
      display: none;
    }

    &--button {
      padding: 18px 80px;
      font-size: 1.8rem;
    }
  }
  .dropdown-toggle-custom {
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
    svg {
      margin-bottom: -2px;
      font-size: 14px;
    }
  }

  .dropdown-custom {
    display: flex;
    align-items: center;
  }
}

.dropdown-menu-custom {
  font-size: inherit !important;
  &.dropdown-menu-custom-rtl {
    text-align: right;
  }
}

.SubMenu__back {
  margin-top: -60px;

  .container {
    @include respond(sm-laptop) {
      max-width: 100% !important;
    }
  }

  @include respond(sm-laptop) {
    margin-top: 0;
  }
}

.SubMenu__responsiv-div {
  display: none;

  @include respond(sm-laptop) {
    display: flex;
  }
}

.responsivelogo {
  width: 130px;

  @include respond(phone) {
    width: 110px;
  }

  @include respond(smallest-phone) {
    width: 100px;
  }

  @include respond(smallest-phone1) {
    width: 82px;
  }
}

.ResponsiveMenu {
  background-color: #fff;
  height: 100vh;
  position: fixed;
  color: $color-text;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 15px 0;
  &__div {
    width: 70%;
    height: 100px;
    background-image: linear-gradient(
      to bottom,
      $color-primary 0%,
      $color-primary-dark 100%
    );
    border-radius: 5px;
    margin: 0 auto;
  }
  .ResponsiveMenu__ul {
    height: 100vh;
    padding: 10px 40px;

    li {
      margin: 20px 0;
    }
  }

  &__open {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include respond(sm-laptop) {
      width: 30vw;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    }
    @include respond(tab-port) {
      width: 50vw;
    }
    @include respond(surface-duo) {
      width: 70vw;
    }
    @include respond(smallest-phone1) {
      width: 80vw;
    }
  }

  &__close {
    width: 0;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

.Burger {
  display: none !important;

  @include respond(sm-laptop) {
    display: flex !important;
  }
}

.responsiveFootermenu {
  display: none;
  width: 100vw;
  height: 60px;
  background-image: url("../../assets/img/Home/backgound-footer.svg");
  background-position: center;
  background-size: cover;
  position: fixed;
  bottom: 0;
  box-shadow: 0px 0px 15px 5px rgba(215, 233, 248, 0.3);
  z-index: 500;

  @include respond(sm-laptop) {
    display: block !important;
  }

  &-plus {
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -53px);
    background: #1375a3;
    font-size: 46px;
    color: #fff;
    border-radius: 50%;
  }
  .icon{
    margin: 0 auto;
  }
  @include respond(phone) {
    .text{
      font-size: 10px ;
    }
  }
  @include respond(smallest-phone) {
    .text{
      // font-size: 10px ;
      display: none !important;
    }
  }

}
.select-box {
  background-color: transparent !important;
  &:focus {
    outline: none;
    border: none;
  }
}
.App__en {
  .ResponsiveMenu {
    left: none;
    right: 0 !important;
  }
  .ResponsiveMenu-li {
    img {
      margin-right: 5px;
    }
  }
}
