.DashboardSidebar{
    padding: 20px ;
    box-shadow: 0 0 5px #eaeaea;
    margin: 4px;
    border-radius: 15px;
    @include respond(sm-laptop) {
        display: none;
      }
    &__userbox{
         background-color: #EBF3F7;
         border-radius: 15px;
         padding: 20px 10px;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
    }             
    &__ul{
        

    }
    &__li{
        margin: 20px 0;
        font-size: 14px;
        font-weight: 700;
    

    }
    &__logoutBtn{
        padding: 0 !important;
        color: #313E45 !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        background: #f5f6f7 !important;
        border-radius: 5px !important;
        width: 100%;
        img{
            margin-top: 5px;
        }
    }
}