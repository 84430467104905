// Colors

// ------------ Primary ------------
$color-primary: #1375A3 ;
$color-primary-light: #F4F8FA;
$color-primary-light-1: #CAF2EC;
$color-primary-light-2: #B1E3FB;
$color-primary-dark: #074979;
$color-primary-dark-1: #292E3D;
///secondary
$color-secondary:#69AF81;
$color-white: #fff;
$color-white-light: #F1F1F1;
//gray
$color-gray-dark: #8D959C;
$color-gray-light: #92A0B0;


$color-text :#313E45;


$br-item: 2rem;
$br-box: 3rem;
$br-input: 9px;

// RESPONSIVE BREAKPOINTS
$bp-xl: 75em; // 1200px
$bp-lg: 62em; // 992px
$bp-md: 48em; // 768px;
$bp-sm: 36em; // 576px;
$bp-xs: 30em; // 480px;
